import React from 'react';
import './AuthorOverlay.css';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const socialLinks = [
    {
        name: 'Portfolio',
        icon: '/favicon.png',
        url: 'https://dineshchhantyal.com'
    }

]

const AuthorOverlay = () => {
    return (
        <div className="author-overlay__wrapper">
            <div className="author-overlay">
                <div className="author-overlay__body">
                    <div>
                        <p> Find more about me on my website.</p>
                        {socialLinks.map((link, index) => (
                            <a key={index} href={link.url}
                                className='author-overlay__link'
                            >
                                <span
                                    className='author-overlay__linkIcon'
                                >                                <OpenInNewIcon fontSize='small' />
                                </span>
                                <span
                                    className='author-overlay__linkText'
                                >{link.name}</span>
                            </a>
                        ))}
                    </div>
                </div>
            </div >
        </div>
    )
}

export default AuthorOverlay